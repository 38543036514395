
*:focus {
    outline: none;
  }

  ::selection {
    background: rgba(10, 10, 10, .7); 
    color: white;
  }
  
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    ::selection {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }
  }